<template>
  <el-dialog
    title="设置"
    :visible.sync="show"
    width="400px"
    class="dialog-vertical"
    @open="onOpen"
    @close="onClose"
  >
    <div class="box" v-loading="loading">
      <span class="tips">合并子架构重名职务的人数</span>
      <el-radio-group v-model="show_children">
        <el-radio :label="1">开启</el-radio>
        <el-radio :label="0">关闭</el-radio>
      </el-radio-group>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button size="small" type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { updateShowChildren } from "../../api/cyc-sorts-config/level-config";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    // 当前行id
    id: {
      required: true,
    },
    // 是否合并子架构同名职务
    showChildren: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      // 合并子架构重名职务的人数
      show_children: 0,
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    onOpen() {
      this.show_children = this.$props.showChildren;
      console.log(this.showChildren);
      console.log(this.id);
    },
    onClose() {},

    onConfirm() {
      this.loading = true;
      updateShowChildren({
        id: this.$props.id,
        show_children: this.show_children,
      })
        .then((res) => {
          this.$message.success("保存成功");
          this.loading = false;
          this.show = false;
          this.$emit('refresh',true)
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  .box {
    padding: 30px 0;
    .tips {
      margin-right: 20px;
    }
  }
}
</style>
