<template>
  <div class="level-config" v-loading="loading">
    <!-- <Reminder :textArr="tenantVersion === 'school' ? schoolReminderText: reminderText" /> -->

    <el-tabs
      id="yjj"
      v-model="activeName"
      @tab-click="handleClick"
      v-if="positionLevels.length"
    >
      <el-tab-pane
        v-for="(tab, index) in positionLevels"
        :label="tab.tab_name"
        :name="tab.id"
        :key="index"
        :id="tab.id"
      >
      </el-tab-pane>
    </el-tabs>

    <el-tree
      style="margin-top: 32px"
      :expand-on-click-node="false"
      :data="positionList"
      node-key="id"
      :props="treeProps"
      default-expand-all
      @node-drag-start="handleDragStart"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDragSuccess"
      draggable
      :allow-drag="allowDrag"
      :allow-drop="allowDrop"
      :indent="30"
    >
      <div
        class="custom-tree-node"
        slot-scope="{ node, data }"
        @click="nodeClick(data)"
      >
        <span class="label">{{ node.label }}</span>
        <!-- <div class="control" v-show="dataRowId === data.id"> -->
        <div class="control">
          <span class="count">{{ data.members_count }} 名会员</span>
          <el-link
            @click="changeShow('Organization', data)"
            v-if="data.child"
            type="primary"
            :underline="false"
            >{{ data.is_show ? "隐藏该架构" : "显示该架构" }}
          </el-link>
          <el-link
            @click="changeShow('Position', data)"
            v-else
            type="primary"
            :underline="false"
            >{{ data.is_show ? "隐藏该职务" : "显示该职务" }}
          </el-link>
          <el-link
            type="primary"
            @click="setShowChildren(data)"
            v-if="data.child"
            :underline="false"
            >设置</el-link
          >
          <el-link
            type="primary"
            v-else
            :underline="false"
            @click="memberSort(data.id)"
            >编辑会员排序</el-link
          >
        </div>
      </div>
    </el-tree>
    <FixedActionBar>
      <el-button @click="saveSort" type="primary" style="margin-bottom: 10px"
        >保存</el-button
      >
    </FixedActionBar>
    <!-- 架构设置弹窗 -->
    <SetDialog
      v-model="ShowChildren"
      :id="dataRowId"
      :showChildren="dataRowChildren"
      @refresh="getPositionLevel"
    ></SetDialog>
  </div>
</template>

<script>
import Reminder from "@/modules/common/components/Reminder.vue";
import Draggable from "vuedraggable";
import Sortable from "../assets/js/sortable";
import SetDialog from "../components/CycSortsConfig/SetDialog.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";

import {
  positionLevel,
  savePositionLevel,
  sortFirstOrganizations,
  updateOrganization,
  updatePosition,
} from "../api/cyc-sorts-config/level-config";
export default {
  components: { Reminder, Draggable, SetDialog, FixedActionBar },
  data() {
    return {
      loading: false,
      saveLoading: false,
      reminderText: [
        "在此配置同层级的会内职务等级顺序",
        "组织架构的申请状态为“允许用户提交申请”才会有预备会员职务显示，预备会员职务无法进行等级顺序调整",
      ],
      schoolReminderText: [
        "在此配置同层级的会内职务等级顺序",
        "校友会的申请状态为“开启认证申请”才会有预备会员职务显示，预备会员职务无法进行等级顺序调整",
      ],
      positionLevels: [],
      positionList: [],
      activeName: "",
      treeProps: {
        label: "name",
        children: "child",
      },
      // 当前操作行的id
      dataRowId: -1,
      // 当前操作行是否合并子架构同名职务
      dataRowChildren: -1,
      // 是否显示架构设置弹窗
      ShowChildren: false,
      // 是否拖动过架构顺序
      isDropChange: false,
    };
  },
  created() {
    this.getPositionLevel();
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    // 是否有修改排序
    if (!this.isDropChange) {
      next();
    } else {
      this.$msgbox
        .confirm("该架构下有修改排序，是否放弃更改？", "提示", {
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: "保存排序",
        })
        .then((res) => {
          next();
        })
        .catch(async (action) => {
          if (action === "cancel") {
            this.saveSort(() => {
              next();
            });
            console.log("保存架构排序");
          } else {
            // 关闭
            next(false);
          }
        });
    }
  },
  methods: {
    //组织架构拖拽方法
    rowDrop() {
      let el = "";
      this.$nextTick(() => {
        el = document.querySelector("#yjj .el-tabs__nav");
        if (!el) {
          return;
        }
        const _this = this;
        Sortable.create(el, {
          animation: 150,
          onEnd({ newIndex, oldIndex }) {
            console.log(newIndex, oldIndex);
            // if(newIndex === oldIndex) {
            //   // 没有移动过
            //   return
            // }
            // 特殊处理, .el-tabs__nav 里第 0 个元素是下划线，后面开始才是 tab
            if (newIndex === 0) {
              newIndex = 1;
            }
            if (oldIndex === 0) {
              oldIndex = 1;
            }
            //oldIIndex拖放前的位置， newIndex拖放后的位置  //positionLevels为遍历的tab签
            const currRow = _this.positionLevels.splice(oldIndex - 1, 1)[0]; //鼠标拖拽当前的el-tabs-pane
            console.log(currRow);
            // 调整位置
            _this.positionLevels.splice(newIndex - 1, 0, currRow); //tableData 是存放所以el-tabs-pane的数组
            // 设置当前选中的架构
            _this.activeName = currRow.id;
            // 切换显示对应的架构职务
            _this.handleClick({ name: currRow.id });
            // 保存一级架构顺序
            _this.saveTopSort();
          },
        });
      });
    },
    // 获取架构树状结构
    getPositionLevel() {
      this.loading = true;
      positionLevel({ is_list_tree: 1 })
        .then((res) => {
          const { data } = res;
          this.recombinationList(data);
          this.positionLevels = data;
          this.activeName = this.activeName ? this.activeName : data[0].id;
          this.positionList = [
            this.positionLevels.find((item) => item.id === this.activeName),
          ];
          this.loading = false;
          this.rowDrop(); //初始化拖拽方法
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 组织架构层级处理
    recombinationList(list) {
      list.forEach((el) => {
        el.tab_name = el.name;
        el.name = el.name + `(${el.level})`;
        if (el.children) {
          el.child = [...el.position, ...el.children];
          this.recombinationList(el.children);
        } else {
          el.child = [...el.position];
        }
      });
    },
    // 点击切换tab数据
    handleClick(e) {
      if (this.isDropChange) {
        this.$msgbox
          .confirm("该架构下有修改排序，是否放弃更改？", "提示", {
            type: "warning",
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            cancelButtonText: "保存排序",
          })
          .then((res) => {
            // 改变架构内容
            this.architectureChange(e);
          })
          .catch(async (action) => {
            if (action === "cancel") {
              // 保存排序之后
              this.saveSort(() => {
                // 改变架构内容
                this.architectureChange(e);
              });
            } else {
              // 改变架构内容
              this.architectureChange(e);
            }
          });
      } else {
        // 改变架构内容

        this.architectureChange(e);
      }
    },
    // 切换一级架构，内容也需要改变
    architectureChange(e) {
      this.getPositionLevel();
      this.isDropChange = false;
      const positions = this.positionLevels.find((item) => item.id === e.name);
      this.positionList = [positions];
      //初始化拖拽方法
      this.rowDrop();
    },
    handleDragStart(draggingNode, ev) {
      console.log("ev", ev);
    },
    // 节点拖拽成功
    handleDragSuccess() {
      this.isDropChange = true;
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log(
        "tree drag end: ",
        draggingNode,
        dropNode,
        dropType,
        this.positionLevels
      );

      console.log(this.positionList);
    },

    // 保存一级架构下的排序
    saveSort(callback) {
      let tree = this.parserTree(this.positionList[0]);
      console.log(tree);
      this.loading = true;
      savePositionLevel({ tree: [tree] })
        .then((res) => {
          this.isDropChange = false;

          this.$message.success(res.msg);
          // 有回调函数调用回调
          this.getPositionLevel();
          callback && callback();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 递归特殊格式用来请求数据
    parserTree(treeObj) {
      let newObj = {
        id: treeObj.id,
        position: [],
        children: [],
      };
      treeObj.child.forEach((item, i) => {
        if (!item.child) {
          newObj.position.push({
            id: item.id,
          });
        } else {
          newObj.children.push(this.parserTree(item));
        }
      });
      return newObj;
    },
    allowDrag(draggingNode, dropNode, type) {
      if (draggingNode.data.name == "预备会员") {
        this.$message.info("预备会员职务不可拖拽排序");
      }

      return (
        // draggingNode.data.level === undefined &&
        draggingNode.data.name !== "预备会员"
      );
    },
    allowDrop(draggingNode, dropNode, type) {
      // 子级架构可以基于同层级调，职务可以基于当前架构下调整，预备会员不可以调

      let flag =
        draggingNode.data.organization_id === dropNode.data.organization_id &&
        ["next", "prev"].includes(type) &&
        dropNode.data.name !== "预备会员" &&
        draggingNode.data.pid === dropNode.data.pid;
      return flag;
    },
    // 获取tab 组件数据
    getComponentData() {
      return {
        on: {
          "tab-click": this.handleClick,
        },
        attrs: {},
        props: {
          value: this.activeName,
        },
      };
    },
    // 保存一级架构排序
    saveTopSort() {
      this.loading = true;
      let ids = this.positionLevels.map((item) => item.id);

      sortFirstOrganizations({ ids })
        .then((res) => {
          this.$message.success("保存一级架构顺序成功");
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 节点被点击才显示操作按钮列 暂时先不用，全部显示
    nodeClick(data) {
      console.log(data);
      this.dataRowId = data.id;
    },
    // 控制显示隐藏架构，职务
    changeShow(type, data) {
      if (type === "Organization") {
        // 显示隐藏架构
        updateOrganization({
          id: data.id,
          is_show: !data.is_show,
        }).then((res) => {
          console.log(res);
          data.is_show = !data.is_show;
          this.$message.success("设置成功");
        });
      } else if (type === "Position") {
        // 显示隐藏职务

        updatePosition({
          id: data.id,
          is_show: !data.is_show,
        }).then((res) => {
          this.$message.success("设置成功");
          data.is_show = !data.is_show;
          console.log(res);
        });
      }
    },
    //是否合并子架构同名职务弹窗
    setShowChildren(data) {
      // 设置当前行信息
      this.dataRowId = data.id;
      this.dataRowChildren = data.show_children;
      // 展示弹窗
      this.ShowChildren = true;
    },
    // 跳转会员编辑排序
    memberSort(id) {
      this.$router.push({
        name: "MemberSorts",
        params: {
          id,
        },
      });
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tree-node {
  ::v-deep.is-dragging {
    background-color: red;
  }
}
// 树节点

::v-deep .el-tree-node {
  padding: 3px 0;
}
.custom-tree-node {
  width: 100%;
  // padding: 30px 0;
  display: flex;
  // justify-content: space-around;
  position: relative;
  span.label {
    font-size: 14px;
  }

  .control {
    width: 360px;
    position: absolute;
    right: 0;
    top: 0;
    span.count {
      display: inline-block;
      margin-right: 60px;
      min-width: 75px;
      font-size: 14px;
      text-align: right;
    }

    .el-link {
      display: inline-block;

      margin-right: 60px;
      // min-width: 75px;
    }
  }
}

// 架构设置弹窗
.setDialog {
  margin-top: 100px;
}
</style>
